import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../../css/CardFlexStyle";
import getLang from "../../components/scripts/getLang";
import { Link } from "gatsby";
import App from "../app";
import PictureFilter from "../../components/scripts/pictureFilter";
import Bokabord from '../../components/bokabord'
import CheckForHighlight from "../../components/scripts/checkForHightlight";
import { SortBySeason2 } from "../../components/scripts/SortBySeasons";
import Searchbar from "../../components/searchbar";
import { ResturangQuery } from '../../components/scripts/graphQlQuerys/restaurantQuery'
import { EnheterQuery } from '../../components/scripts/graphQlQuerys/enheterQuery' 
import Hero from '../../components/hero'
import Taggar from "../../components/taggar";

const Lunch = () => {
  let [english] = useGlobal("English");
  const pageType = "Restaurang Lunch";
  let heroData = getLang(english, ResturangQuery().allContentfulRestaurangSida);
  let heroTema = null;
  const [SearchKeyword, setSearchKeyword] = useState(' ');

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "Lunch", newURL);
    }
  });

  if(ResturangQuery('Lunch').allContentfulRestaurangTeman.edges.length !== 0 & ResturangQuery('Lunch').allContentfulRestaurangTeman.edges.length !== undefined){
    heroTema = getLang(english, ResturangQuery('Lunch').allContentfulRestaurangTeman)
  }

  let { seoTitel, seoDescription } = heroTema[0].node
  let { node_locale, Synligataggar, introTextlunch } = heroData[0].node

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero data={heroData} heroTema={heroTema} introText={introTextlunch} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <Taggar mainPage="Restaurant" subPage="Lunch" data={Synligataggar} />
      <ResturantGraphql pageType={pageType} SearchKeyword={SearchKeyword} english={english} />
      <Bokabord lang={node_locale} />
    </App>
  );
};

export default Lunch;

const ResturantGraphql = props => {
  let { SearchKeyword, english, pageType } = props;
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let regex2 = new RegExp("lunch", "gi");
  let restaurant = getLang(english, EnheterQuery())
  restaurant = SortBySeason2(restaurant)
  restaurant = CheckForHighlight(pageType, restaurant)
    .filter(edges => {
      return edges.node.sida.includes("Restaurang");
    })
    .filter(restaurant => {
      let newString = restaurant.node.skord.toString();

      return newString.match(regex) && newString.match(regex2);
    })
    .map((item, index) => {
      let orderOne = "";
      if (item.node.highlight) {
        orderOne = "orderOne";
      }
      return (
        <Link
          className={"textDecorationNone linkOrder " + orderOne}
          key={index}
          to={`/restaurant/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex specialItem"}
            fluid={PictureFilter('img1', item)}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{restaurant}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};
